import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import Loader2 from "../../utilities/Loader2";
import Denied from "../../utilities/Denied";
import { DELETE, GET } from "../../axiosHelper/Helper";
import { deleteModal, deleteMsg, failMsg } from "../../utilities/Notifcation";

export default function HodCrudTable() {

    const [department, setdepartment] = useState([]);
    const [Token, setToken] = useState(sessionStorage.getItem("token"));
    const [Auth, setAuth] = useState();
    const [delmsg, setDelmsg] = useState(true);
    const [delId, setDelId] = useState()
    const [Api, setApi] = useState(localStorage.getItem("LocalApi"));
    const [loader, setloader] = useState(true);
  
  
  
    useEffect(() => {
     
      loaddepartment();
      
    }, []);
  
    const checkdeletedepartment = async (id) => {
      if (delmsg == true) {
        setDelmsg(false)
        setDelId(id)
      }
  
      
    };
    
    const deletedepartment = () =>{
  
        let id = delId
        DELETE(`department/${id}`)
        .then(res=>{
          deleteMsg(res.message);
          loaddepartment();
        })
        .catch(err=>{
          failMsg(err);
        })

       
        setDelmsg(true)
    }
  
    
  
  
    const loaddepartment = () => {
     
      GET('department')
      .then(res=>{
        setloader(false);
        setAuth(res.code);
        setdepartment(res.data);
      })
      .catch(err=>{
        failMsg(err);
      })
   
    };

    return (
        <div>
            <div>
      {Auth == 403 ? (
       <Denied />
      ) : (
        <div>
          <div className="flex justify-between px-4 py-6">
            <p className="text-3xl font-bold ">Admin Table</p>

            <Link
              type="button"
              class="py-2 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              to="/department/add"
            >
              <img
                className="mr-2"
                src="https://img.icons8.com/android/20/ffffff/plus.png"
              />
              Add Department
            </Link>
          </div>
          <div className={delmsg ? " flex justify-start  lg:justify-center  overflow-y-scroll  px-4" : " flex justify-start  lg:justify-center  overflow-y-hidden  px-4"} style={{ height: "75vh" }}>
          <div className={delmsg ? "hidden" : "absolute top-0 left-0 grid place-items-center w-full h-full z-40"} style={{ background: "#0000006e" }}>
              <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
                <div class="w-full h-full text-center">
                  <div class="flex h-full flex-col justify-between">
                    <svg width="40" height="40" class="mt-4 w-12 h-12 m-auto text-red-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"></path>
                    </svg>
                    <p class="text-gray-800 dark:text-gray-200 text-xl font-bold mt-4">Remove row</p>
                    <p class="text-gray-600 dark:text-gray-400 text-xs py-2 px-6">Are you sure you want to delete this row ?</p>
                    <div class="flex items-center justify-between gap-4 w-full mt-8">
                      <button
                        type="button"
                        onClick={() => deletedepartment()}
                        class="py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => setDelmsg(true)}
                        class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-red-500 focus:ring-offset-red-200 text-red-500  w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table className="table p-4 w-full bg-white shadow rounded-lg ">
              <thead >
                <tr>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    #
                  </th>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    Name
                  </th>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    email
                  </th>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    Role
                  </th>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    Status
                  </th>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    Created At
                  </th>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    Updated At
                  </th>
                  <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">
                    Actions
                  </th>
                </tr>
              </thead>
              {!loader ? (
                <tbody className="text-center">
                  {department.map((user, index) => (
                    <tr>
                      <th>{index + 1}</th>
                      <td>{user.full_name}</td>
                      <td>{user.email_id}</td>
                      <td>{user.role}</td>
                      <td>{user.status}</td>
                      <td>{user.created_at}</td>
                      <td>{user.updated_at}</td>
                      <td className="py-7">
                        <Link
                          to={`/department/edit/${user.id}`}
                          className="px-3 py-1 mx-2 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-white border-2 border-blue-900 focus:outline-none"
                        >
                          EDIT
                        </Link>
                        <button
                        disabled={true}
                        style={{cursor:"not-allowed"}}
                          className="px-3 py-1  transition ease-in duration-200 uppercase rounded-full hover:bg-red-800 hover:text-white border-2 border-red-900 focus:outline-none"
                          onClick={() => checkdeletedepartment(user.id)}
                        >
                          DELETE
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div className="grid place-content-center h-screen absolute top-24 left-0 w-full">
                  <Loader2 />
                </div>
              )}
            </table>
          </div>
        </div>
      )}
    </div>
        </div>
    )
}
