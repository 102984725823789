// import React, { useState, useEffect } from "react";
// import {useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import { GET } from "../../../axiosHelper/Helper";
// import { failMsg } from "../../../utilities/Notifcation";

// export default function EditDeskCrudTable() {
//   const { id } = useParams();
//   const [task1, set_task1] = useState({
//     secretary_total: null,
//     department_total: null,
//     lw_total: null,
//     lw_completed: null,
//   });
//   const [message, set_message] = useState("");
//   const [alert, setAlert] = useState(false);
//   const [alert_400, setAlert_400] = useState(false);

//   const [Token, setToken] = useState(sessionStorage.getItem("token"));
//   const [Api, setApi] = useState(localStorage.getItem("LocalApi"));
//   let navigate = useNavigate();

//   const onLoad = async () => {
//     // await axios
//     //   .get(`${Api}task/${id}`, {
//     //     headers: {
//     //       Authorization: `Bearer ${Token}`,
//     //     },
//     //   })
//     //   .then((res) => {
//     //     // res.data.
//     //     set_task1({ ...task1, secretary_total: res.data.data[0].secretary_total, department_total: res.data.data[0].department_total });
//     //     // console.log(res.data.message);
//     //     if (res.data.code == 500) {
//     //       setAlert(true);
//     //     }
//     //     if (res.data.code == 400) {
//     //       console.log("res.data.message");
//     //       setAlert_400(true);
//     //       set_message(res.data.message);
//     //     } else {
//     //     }
//     //     console.log('task',task1)
//     //   });

//       GET(`task/${id}`)
//       .then(res=>{
//         set_task1({ ...task1, secretary_total: res.data[0].secretary_total, department_total: res.data[0].department_total });
//         console.log(res);
//       }).catch(err=>{
//         failMsg(err);
//       })
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     // await axios
//     //   .put(`${Api}task/${id}`, task1, {
//     //     headers: {
//     //       Authorization: `Bearer ${Token}`,
//     //     },
//     //   })
//     //   .then((res) => {
//     //     // res.data.
//     //       console.log(res.data.message);
//     //       setAlert_400(true);
//     //       set_message(res.data.message);
//     //     if (res.data.code == 500) {
//     //       setAlert(true);
//     //     }
//     //     if (res.data.code == 400) {
//     //       console.log("res.data.message");
//     //       setAlert_400(true);
//     //       set_message(res.data.message);
//     //     } else {
//     //       history.push("/dashboard");
//     //     }
//     //   });
//   };

//   const HandleChange = (e) => {
//     // console.log(e.target.value);
//     set_task1({ ...task1, [e.target.name]: e.target.value });
//   };

//   useEffect(() => {
//     onLoad();

//   }, []);
//   return (
//     <div>
//       <div className="flex justify-center items-center absolute top-0 w-full h-full p-4 lg:p-0" style={{ zIndex: "-1" }}>
//         <div className="bg-white rounded-lg shadow mt-24 w-full lg:w-1/2">
//           <div className="px-4 py-8 sm:px-10">
//             <div className="relative mt-6">
//               <p className="text-3xl font-bold text-blue-700">Edit Task</p>
//               <div className={alert ? "bg-yellow-200 border-yellow-600 my-3 text-yellow-600 border-l-4 p-4" : "hidden"} role="alert">
//                 <p className="font-bold">File Type / Status is required</p>
//               </div>
//               {alert_400 ? (
//                 <div className="bg-yellow-200 border-yellow-600 my-3 text-yellow-600 border-l-4 p-4" role="alert">
//                   <p className="font-bold">{message}</p>
//                 </div>
//               ) : null}
//               <div className="absolute inset-0 flex items-center"></div>
//             </div>
//             <form onSubmit={(e) => onSubmit(e)}>
//               <div className="mt-6">
//                 <div className="w-full space-y-6">
//                   <div className="w-full">
//                     {/* <label htmlFor="">Last Week Secretary Total</label> */}
//                     <div className=" relative ">
//                       <input
//                         type="number"
//                         value={task1.lw_total}
//                         required={true}
//                         id="task"
//                         className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
//                         placeholder="एकूण संदर्भ (मागील शिल्लक + आठवड्यात प्राप्त)"
//                         name="lw_total"
//                         // value={File.title}
//                         onChange={(e) => HandleChange(e)}
//                       />
//                     </div>
//                   </div>
//                   <div className="w-full">
//                     {/* <label htmlFor="">Last Week Completed Total</label> */}
//                     <div className=" relative ">
//                       <input
//                         type="number"
//                         value={task1.lw_completed}
//                         required={true}
//                         id="task"
//                         className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
//                         placeholder="अहवाल कालावधीत निकाली काढलेले संदर्भ"
//                         name="lw_completed"
//                         // value={File.title}
//                         onChange={(e) => HandleChange(e)}
//                       />
//                     </div>
//                   </div>
//                   <div className="w-full">
//                     {/* <label htmlFor="">Secretary Total</label> */}
//                     <div className=" relative ">
//                       <input
//                         type="number"
//                         value={task1.secretary_total}
//                         required={true}
//                         id="task"
//                         className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
//                         placeholder="सह सचिव उप सचिव स्तरावर सादर केलेल्या प्रकरणाची एकूण संख्या"
//                         name="secretary_total"
//                         // value={File.title}
//                         onChange={(e) => HandleChange(e)}
//                       />
//                     </div>
//                   </div>

//                   <div className="w-full">
//                     {/* <label htmlFor="">Department Total</label> */}
//                     <div className=" relative ">
//                       <input
//                         type="number"
//                         required={true}
//                         id="completeTask"
//                         value={task1.department_total}
//                         className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
//                         placeholder="अ.मु.म/ इतर विभाग शासनास सादर केलेल्या प्रकरणांची संख्या"
//                         name="department_total"
//                         //value={File.description}
//                         onChange={(e) => HandleChange(e)}
//                       />
//                     </div>
//                   </div>

//                   <div>
//                     <span className="block w-full rounded-md shadow-sm">
//                       <button
//                         type="submit"
//                         className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
//                       >
//                         Update Task
//                       </button>
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GET, PUT } from '../../../axiosHelper/Helper'
import Button from '../../../utilities/Button'
import Inputfileds from '../../../utilities/Inputfileds'
import { failMsg, getTodayDate, reverseString, successMsg } from '../../../utilities/Notifcation'

const EditTask = () => {
  const { id } = useParams();
  const navigate=useNavigate();
  const initialState = { secretary_total: "", department_total: "", lw_total: "", lw_completed: "", from_date: "", to_date: "" }
  const [formData, setformData] = useState(initialState)
  const [formError, setformError] = useState(initialState)
  const [isLoading, setisLoading] = useState(false)
  const [focus, setfocus] = useState(false)
  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value })
  }
  const formValidation = (object) => {
    const error = {};
    if (object.secretary_total.length === 0) {
      error.secretary_total = "This Fields is Required !"
    }
    if (object.department_total.length === 0) {
      error.department_total = "This Fields is Required !"
    }
    if (object.lw_total.length === 0) {
      error.lw_total = "This Fields is Required !!"
    }

    if (object.lw_completed.length === 0) {
      error.lw_completed = "This Fields is Required ! !"
    }
    if (object.from_date.length === 0) {
      error.from_date = "This Fields is Required ! !"
    }
    if (object.to_date.length === 0) {
      error.to_date = "This Fields is Required ! !"
    }

    return error;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setfocus(true);
    setisLoading(true);
    // setformError(formValidation(formData))

    if (Object.keys(formValidation(formData)).length === 0) {
      editTask();
    

    }
    else {

      setisLoading(false);
    }
  }
  //eslint-disable-next-line
  const editTask = () => {
    PUT(`task/${id}`, formData)
      .then(res => {
        if(res.code === 200){
          successMsg(res.message)
          navigate('/dashboard');
        }
        else{
          failMsg(JSON.stringify(res.message));
        }
        setisLoading(false);
      })
      .catch(err => {
        failMsg(err);
        setisLoading(false);
      })
  }

  useEffect(() => {
    if (focus) {
      setformError(formValidation(formData))
    }


  }, [formData, focus])

  const loadTask=()=>{
    GET(`task/${id}`)
          .then(res=>{
            setformData({ ...formData, from_date:reverseString(res.data[0].from_date),to_date:reverseString(res.data[0].to_date), secretary_total: res.data[0].secretary_total, department_total: res.data[0].department_total });
            console.log(res);
            setisLoading(false)
          }).catch(err=>{
            failMsg(err);
            setisLoading(false)
          })
      
  }
  useEffect(()=>{
    setisLoading(true);
    loadTask();
    //eslint-disable-next-line
  },[])
  return (
    <>
      <div className='formContainer'>
        <div className='flex justify-between items-center'>
          <h1 className='heading'>Edit Task</h1>
          <Link className='backBtn' to={'/dashboard'}>Back</Link>
        </div>
        <form className='filedsContainer' onSubmit={handleSubmit} >
          <Inputfileds label={'From Date'} error={formError.from_date} type={'date'} placeHolder={''} name={'from_date'} value={formData.from_date} handleChange={handleChange} min={getTodayDate()} disabled={false} />

          <Inputfileds label={'To Date'} error={formError.to_date} type={'date'} placeHolder={''} name={'to_date'} value={formData.to_date} handleChange={handleChange} min={getTodayDate()} disabled={false}  />

          <Inputfileds label={"एकूण संदर्भ (मागील शिल्लक + आठवड्यात प्राप्त)"} error={formError.lw_total} type={'number'} placeHolder={"एकूण संदर्भ (मागील शिल्लक + आठवड्यात प्राप्त)"} name={'lw_total'} value={formData.lw_total} handleChange={handleChange} min='1' />

          <Inputfileds label={"अहवाल कालावधीत निकाली काढलेले संदर्भ"} error={formError.lw_completed} type={'number'} placeHolder={"अहवाल कालावधीत निकाली काढलेले संदर्भ"} name={'lw_completed'} value={formData.lw_completed} handleChange={handleChange} min='1' />

          <Inputfileds label={"सह सचिव उप सचिव स्तरावर सादर केलेल्या प्रकरणाची एकूण संख्या"} error={formError.secretary_total} type={'number'} placeHolder={"सह सचिव उप सचिव स्तरावर सादर केलेल्या प्रकरणाची एकूण संख्या"} name={'secretary_total'} value={formData.secretary_total} handleChange={handleChange} min='1' />

          <Inputfileds label={"अ.मु.म/ इतर विभाग शासनास सादर केलेल्या प्रकरणांची संख्या"} error={formError.department_total} type={'number'} placeHolder={"अ.मु.म/ इतर विभाग शासनास सादर केलेल्या प्रकरणांची संख्या"} name={'department_total'} value={formData.department_total} handleChange={handleChange} min='1' />

          <div className='lg:col-span-2'>
            <Button text={'Edit Task'} loader={isLoading} />
          </div>

        </form>
      </div>
    </>
  )
}

export default EditTask
