import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { POST } from '../../../axiosHelper/Helper'
import Button from '../../../utilities/Button'
import Inputfileds from '../../../utilities/Inputfileds'
import { failMsg, getTodayDate, successMsg } from '../../../utilities/Notifcation'

const AddTask = () => {
  const navigate=useNavigate();
  const initialState = { secretary_total: "", department_total: "", lw_total: "", lw_completed: "", from_date: "", to_date: "" }
  const [formData, setformData] = useState(initialState)
  const [formError, setformError] = useState(initialState)
  const [isLoading, setisLoading] = useState(false)
  const [focus, setfocus] = useState(false)
  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value })
  }
  const formValidation = (object) => {
    const error = {};
    if (object.secretary_total.length === 0) {
      error.secretary_total = "This Fields is Required !"
    }
    if (object.department_total.length === 0) {
      error.department_total = "This Fields is Required !"
    } 
    if (object.lw_total.length === 0) {
      error.lw_total = "This Fields is Required !!"
    } 

    if (object.lw_completed.length === 0) {
      error.lw_completed = "This Fields is Required ! !"
    } 
    if (object.from_date.length === 0) {
      error.from_date = "This Fields is Required ! !"
    }
    if (object.to_date.length === 0) {
      error.to_date = "This Fields is Required ! !"
    }

    return error;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setfocus(true);
    setisLoading(true);
    // setformError(formValidation(formData))

    if (Object.keys(formValidation(formData)).length === 0) {
      addTask();
     

    }
    else {

      setisLoading(false);
    }
  }
  //eslint-disable-next-line
  const addTask = () => {
    POST('task', formData)
      .then(res => {
        if(res.code === 200){
          successMsg(res.message)
          navigate('/dashboard');
        }
        else{
          failMsg(JSON.stringify(res.message));
        }
        setisLoading(false);
      })
      .catch(err => {
        failMsg(err);
      })
  }

  useEffect(() => {
    if (focus) {
      setformError(formValidation(formData))
    }

   
  }, [formData, focus])

  
  return (
    <>
      <div className='formContainer'>
        <div className='flex justify-between items-center'>
          <h1 className='heading'>Add Task</h1>
          <Link className='backBtn' to={'/dashboard'}>Back</Link>
        </div>
        <form className='filedsContainer' onSubmit={handleSubmit} >
          <Inputfileds label={'From Date'} error={formError.from_date} type={'date'} placeHolder={''} name={'from_date'} value={'04-07-2022'} handleChange={handleChange} min={getTodayDate()}  />

          <Inputfileds label={'To Date'} error={formError.to_date} type={'date'} placeHolder={''} name={'to_date'} value={formData.to_date} handleChange={handleChange} min={getTodayDate()} />

          <Inputfileds label={"एकूण संदर्भ (मागील शिल्लक + आठवड्यात प्राप्त)"} error={formError.lw_total} type={'number'} placeHolder={"एकूण संदर्भ (मागील शिल्लक + आठवड्यात प्राप्त)"} name={'lw_total'} value={formData.lw_total} handleChange={handleChange} min='1' />

          <Inputfileds label={"अहवाल कालावधीत निकाली काढलेले संदर्भ"} error={formError.lw_completed} type={'number'} placeHolder={"अहवाल कालावधीत निकाली काढलेले संदर्भ"} name={'lw_completed'} value={formData.lw_completed} handleChange={handleChange} min='1' />

          <Inputfileds label={"सह सचिव उप सचिव स्तरावर सादर केलेल्या प्रकरणाची एकूण संख्या"} error={formError.secretary_total} type={'password'} placeHolder={"सह सचिव उप सचिव स्तरावर सादर केलेल्या प्रकरणाची एकूण संख्या"} name={'secretary_total'} value={formData.secretary_total} handleChange={handleChange} min='1' />

          <Inputfileds label={"अ.मु.म/ इतर विभाग शासनास सादर केलेल्या प्रकरणांची संख्या"} error={formError.department_total} type={'password'} placeHolder={"अ.मु.म/ इतर विभाग शासनास सादर केलेल्या प्रकरणांची संख्या"} name={'department_total'} value={formData.department_total} handleChange={handleChange} min='1' />

          <div className='lg:col-span-2'>
            <Button text={'Add Task'} loader={isLoading} />
          </div>

        </form>
      </div>
    </>
  )
}

export default AddTask
