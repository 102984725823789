import React from 'react'

const Dropdown = ({ label, name, value, handleChange, error, children }) => {
  return (
    <>
      <label className="block relative mb-8 ">
        <span className="text-black font-semibold">{label}</span>
        <select
          name={name}
          // required={true}
          value={value}
          onChange={(e) => { handleChange(e) }}
          className={`form-select mt-1 block  rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent`}
        >
          <option value=''>-- Select Role --</option>
          {children}
        </select>
        <p className='absolute text-red-500 font-semibold'>{error}</p>
      </label>
    </>
  )
}

export default Dropdown
