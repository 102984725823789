import React, { useEffect, useRef, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactToPrint from "react-to-print";
import { GET } from "../../axiosHelper/Helper";
import Loader2 from "../../utilities/Loader2";
import { failMsg } from "../../utilities/Notifcation";

export default function Report() {
  const [loading, setloading] = useState(false);
  const [testJson, setTestJson] = useState([]);
  const [date, setdate] = useState({});
  const [filterDate, setfilterDate] = useState({ from_date: "", to_date: "" });
  const [AllTotals, setAllTotals] = useState({
    desk_all_total: "",
    completed_all_task: "",
    grand_all_total: "",
    total_all_task: "",
    from_date: "",
    to_date: "",
    y_desk_all_total: "",
    grand_cumulative_completed_total: "",
    grand_cumulative_grand_total: "",
    grand_cumulative_all_total: "",
    y_from_date: "",
    grand_total_lw: "",
  });
  const onLoad = () => {
    setloading(true);

    GET(`report?from=${filterDate?.from_date}&to=${filterDate?.to_date}`)
      .then((res) => {
        setTestJson(res.data);
        setdate(res.data[0].total_info[0]);
        // console.log(res.data.data[0].total_info[0]);

        setloading(false);
      })
      .catch((err) => {
        failMsg(err);
      });
  };
  const grandtotal = () => {
    GET("filter_task_by_department")
      .then((res) => {
        if (res.code == 500) {
          failMsg(res.message);
        } else if (res.code == 200) {
          setAllTotals({
            desk_all_total: res.desk_all_total,
            completed_all_task: res.completed_all_task,
            grand_all_total: res.grand_all_total,
            total_all_task: res.total_all_task,
            from_date: res.from_date,
            to_date: res.to_date,
            y_desk_all_total: res.y_desk_all_total,
            grand_cumulative_completed_total:
              res.grand_cumulative_completed_total,
            grand_cumulative_grand_total: res.grand_cumulative_grand_total,
            grand_cumulative_all_total: res.grand_cumulative_all_total,
            grand_total_lw: res.grand_total_lw,
            grand_completed_lw: res.grand_completed_lw,
            y_from_date: res.y_from_date,
          });
        }
        if (res.code == 400) {
          console.log(res.data.message);
          failMsg(res.message);
        }
      })
      .catch((err) => {
        failMsg(err);
      });
  };
  const weeksBetween = (d1, d2) => {
    // return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
    const difftime = Math.abs(d2 - d1);
    const diffdays = Math.ceil(difftime / (1000 * 60 * 60 * 24));
    const week = diffdays / 7;
    console.log(week);
    return Math.round(week);
  };

  useEffect(() => {
    onLoad();
    grandtotal();
    console.log(weeksBetween(new Date(2021, 4, 1), new Date()));
  }, []);

  const componentRef = useRef();

  const handleDateChange = (e) => {
    setfilterDate((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <div className="w-full p-5 space-x-2">
          <label>
            <span className="mr-1 font-semibold">From Date:</span>
            <input
              onChange={handleDateChange}
              type={"date"}
              name={"from_date"}
              value={filterDate?.from_date}
              className="p-2 border border-black rounded-md"
            />
          </label>
          <label>
            <span className="mr-1 font-semibold">To Date:</span>
            <input
              type={"date"}
              name={"to_date"}
              value={filterDate?.to_date}
              onChange={handleDateChange}
              className="p-2 border border-black rounded-md"
            />
          </label>
          <button
            className="px-4 py-2 text-white bg-blue-500 rounded-md"
            onClick={() => {
              if (filterDate?.from_date && filterDate?.to_date) {
                onLoad();
              }
            }}
          >
            Filter
          </button>
          <button
            style={{ backgroundColor: " rgb(251 146 60)" }}
            className="px-4 py-2 text-white rounded-md"
            onClick={() => {
              setfilterDate({ from_date: "", to_date: "" });
            }}
          >
            Reset
          </button>
        </div>

        <div className="w-full">
          <p
            style={{
              fontWeight: "bold",
              textAlign: "center",
              backgroundColor: "#8ea9db",
              padding: "20px",
              border: "1px solid black",
            }}
          >
            सह सचिव / उप सचिव निहाय प्रकरण निर्गतीचा साप्ताहिक अहवाल
          </p>
        </div>
        <table
          id="table-to-xls"
          ref={componentRef}
          className="tg"
          style={{ width: "100%" }}
        >
          <thead className="bg-gray-300">
            <tr>
              <th className="tg-7btt" rowspan="2">
                अ.क
              </th>
              <th className="tg-7btt" rowspan="2">
                सह सचिव/ उप <br />
                सचिव यांचे नाव
              </th>
              <th className="tg-7btt" rowspan="2">
                कार्यासन क्र.
              </th>
              <th className="tg-7btt" rowspan="2">
                एकूण संदर्भ
                <br /> (मागील
                <br /> शिल्लक + <br />
                आठवड्यात
                <br /> प्राप्त)
              </th>
              <th className="tg-7btt" rowspan="2">
                अहवाल <br />
                कालावधीत <br />
                निकाली <br />
                काढलेले संदर्भ
              </th>
              <th className="tg-7btt" colspan="3">
                {` दि. ${date?.from_date} ते दि. ${date?.to_date}`}
              </th>
              {/* दि. 14-02-2022 ते दि. 18-02-2022 */}
              <th className="tg-7btt" colspan="3">
                {/* {`दिनांक ${AllTotals.y_from_date} पासून सादर केलेल्या प्रकरणांची संख्या`} */}
                दिनांक 01-04-2021 पासून सादर केलेल्या प्रकरणांची संख्या
              </th>
            </tr>
            <tr>
              <th className="tg-7btt">
                सह सचिव व <br />
                उप सचिव
                <br /> स्तरावर सादर
              </th>
              <th className="tg-7btt">
                अ.मु.स./ इतर <br />
                विभाग / शासनास <br />
                सादर केलेल्या
                <br /> प्रकरणाची संख्या
              </th>
              <th className="tg-7btt">
                सादर केलेल्या
                <br /> एकूण प्रकरणांची
                <br /> संख्या
              </th>
              <th className="tg-7btt">
                सह सचिव उप
                <br /> सचिव स्तरावरील
                <br />
                प्रकरणे
              </th>
              <th className="tg-7btt">
                अ.मुस / इतर
                <br /> विभाग/शासनास <br />
                सादर केलेली प्रकरणे
              </th>
              <th className="tg-7btt">एकूण प्रकरणे</th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray-300">
              <td className="tg-7btt">1</td>
              <td className="tg-7btt">2</td>
              <td className="tg-7btt">3</td>
              <td className="tg-7btt">4</td>
              <td className="tg-7btt">5</td>
              <td className="tg-7btt">6(अ)</td>
              <td className="tg-7btt">6(ब)</td>
              <td className="tg-7btt">6(अ) +6 (ब) = 6क</td>
              <td className="tg-7btt">7 (अ)</td>
              <td className="tg-7btt">7 (ब)</td>
              <td className="tg-7btt">7(अ) +7(ब) = 7क</td>
            </tr>
            {loading ? (
              <div className="absolute flex items-center justify-center w-full p-20 ">
                <Loader2 />
              </div>
            ) : (
              testJson.map((task, index) => (
                <>
                  <tr>
                    <td className="tg-7btt" rowspan={task.desk_info.length + 4}>
                      {index + 1}
                    </td>
                    <td
                      className="tg-c3ow "
                      rowspan={task.desk_info.length + 1}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {task.depart_name}
                      </span>
                    </td>
                  </tr>
                  {task.desk_info.map((desk, index) => (
                    <tr>
                      <td className="tg-c3ow">{desk.desk_name}</td>
                      <td className="tg-c3ow">{desk.l_secretary_total}</td>
                      <td
                        className={`${
                          desk.l_completed_task >= 75
                            ? "tg-c3ow  bg-green-500"
                            : ""
                        } ${
                          desk.l_completed_task <= 74 &&
                          desk.l_completed_task >= 60
                            ? "tg-c3ow  bg-yellow-600"
                            : ""
                        } ${
                          desk.l_completed_task <= 59
                            ? "tg-c3ow  bg-red-500"
                            : ""
                        }`}
                      >
                        {desk.l_completed_task}
                      </td>
                      <td className="tg-c3ow">{desk.total_task}</td>
                      <td className="tg-c3ow">{desk.completed_task}</td>
                      <td
                        className={`${
                          desk.grand_total >= 25 ? "tg-c3ow  bg-green-500" : ""
                        } ${
                          desk.grand_total <= 24 && desk.grand_total >= 15
                            ? "tg-c3ow  bg-yellow-600"
                            : ""
                        } ${
                          desk.grand_total <= 14 ? "tg-c3ow  bg-red-500" : ""
                        }`}
                      >
                        {desk.grand_total}
                      </td>
                      <td className="tg-c3ow">{desk.cumulative_sec_total}</td>
                      <td className="tg-c3ow">{desk.cumulative_com_total}</td>
                      <td className="tg-c3ow">{desk.cumulative_grand_total}</td>
                    </tr>
                  ))}

                  <tr className="bg-gray-400">
                    <td className="tg-7btt">Total</td>
                    {task.total_info.map((total, index) => (
                      <>
                        <td className="tg-7btt">{total.desk_count}</td>
                        <td className="tg-7btt">{total.grand_l_totaltask}</td>
                        <td className="tg-7btt">
                          {total.grand_l_completedtask}
                        </td>
                        <td className="tg-7btt">{total.total_all_task}</td>
                        <td className="tg-7btt">{total.completed_all_task}</td>
                        <td className="tg-7btt">{total.grand_all_total}</td>
                        <td className="tg-7btt">
                          {total.grand_cumulative_sec_total}
                        </td>
                        <td className="tg-7btt">
                          {total.grand_cumulative_com_total}
                        </td>
                        <td className="tg-7btt">
                          {total.grand_cumulative_grand_total}
                        </td>
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="tg-7btt" colspan="2">
                      Average Total Disposal per desk
                    </td>
                    {task.total_info.map((total, index) => (
                      <>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.grand_l_totaltask / total.desk_count
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.grand_l_completedtask / total.desk_count
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.total_all_task / total.desk_count
                          ).toFixed(2)}{" "}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.completed_all_task / total.desk_count
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.grand_all_total / total.desk_count
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.grand_cumulative_sec_total / total.desk_count
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.grand_cumulative_com_total / total.desk_count
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            total.grand_cumulative_grand_total /
                              total.desk_count
                          ).toFixed(2)}
                        </td>
                      </>
                    ))}
                  </tr>
                  <tr>
                    <td className="tg-7btt" colspan="7">
                      Average disposal per desk per week{" "}
                    </td>
                    {task.total_info.map((desk_total, index) => (
                      <>
                        <td className="tg-7btt">
                          {parseFloat(
                            desk_total.grand_cumulative_sec_total /
                              weeksBetween(
                                new Date("2021 , 4 ,1"),
                                new Date()
                              ) /
                              task.desk_info.length
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            desk_total.grand_cumulative_com_total /
                              weeksBetween(
                                new Date("2021 , 4 ,1"),
                                new Date()
                              ) /
                              task.desk_info.length
                          ).toFixed(2)}
                        </td>
                        <td className="tg-7btt">
                          {parseFloat(
                            desk_total.grand_cumulative_grand_total /
                              weeksBetween(
                                new Date("2021 , 4 ,1"),
                                new Date()
                              ) /
                              task.desk_info.length
                          ).toFixed(2)}
                        </td>
                      </>
                    ))}
                  </tr>
                </>
              ))
            )}
            <tr className="bg-gray-400">
              <td className="tg-7btt" colspan="2">
                <span style={{ fontWeight: "bold" }}>एकूण</span>
              </td>
              <td className="tg-7btt">{AllTotals.desk_all_total}</td>
              <td className="tg-7btt">{AllTotals.grand_total_lw}</td>
              <td className="tg-7btt">{AllTotals.grand_completed_lw}</td>
              <td className="tg-7btt">{AllTotals.total_all_task}</td>
              <td className="tg-7btt">{AllTotals.completed_all_task}</td>
              <td className="tg-7btt">{AllTotals.grand_all_total}</td>
              <td className="tg-7btt">
                {AllTotals.grand_cumulative_all_total}
              </td>
              <td className="tg-7btt">
                {AllTotals.grand_cumulative_completed_total}
              </td>
              <td className="tg-7btt">
                {AllTotals.grand_cumulative_grand_total}
              </td>
            </tr>
            <tr className="bg-gray-100">
              <td className="tg-7btt" colspan="3">
                <span style={{ fontWeight: "bold" }}>Average</span>
              </td>
              {/* <td className="tg-l2ex">{AllTotals.desk_all_total}</td> */}
              <td className="tg-7btt">
                {(AllTotals.grand_total_lw / AllTotals.desk_all_total).toFixed(
                  2
                )}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.grand_completed_lw / AllTotals.desk_all_total
                ).toFixed(2)}
              </td>
              <td className="tg-7btt">
                {(AllTotals.total_all_task / AllTotals.desk_all_total).toFixed(
                  2
                )}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.completed_all_task / AllTotals.desk_all_total
                ).toFixed(2)}
              </td>
              <td className="tg-7btt">
                {(AllTotals.grand_all_total / AllTotals.desk_all_total).toFixed(
                  2
                )}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.grand_cumulative_all_total /
                  AllTotals.desk_all_total
                ).toFixed(2)}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.grand_cumulative_completed_total /
                  AllTotals.desk_all_total
                ).toFixed(2)}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.grand_cumulative_grand_total /
                  AllTotals.desk_all_total
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="tg-7btt" colspan="8">
                Average disposal per desk per week{" "}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.grand_cumulative_all_total /
                  AllTotals.desk_all_total /
                  weeksBetween(new Date("2021 , 4 ,1"), new Date())
                ).toFixed(2)}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.grand_cumulative_completed_total /
                  AllTotals.desk_all_total /
                  weeksBetween(new Date("2021 , 4 ,1"), new Date())
                ).toFixed(2)}
              </td>
              <td className="tg-7btt">
                {(
                  AllTotals.grand_cumulative_grand_total /
                  AllTotals.desk_all_total /
                  weeksBetween(new Date("2021 , 4 ,1"), new Date())
                ).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify-end my-4">
          <ReactToPrint
            trigger={() => (
              <button
                type="button"
                className="flex items-center justify-center px-4 py-2 mr-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-red-600 rounded-lg shadow-md hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 "
              >
                <img
                  src="https://img.icons8.com/ios-glyphs/20/ffffff/pdf.png"
                  className="mr-2"
                  alt=""
                />
                PDF
              </button>
            )}
            content={() => componentRef.current}
          />
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="px-4 py-2 text-base font-semibold text-center text-white transition duration-200 ease-in bg-green-600 rounded-lg shadow-md download-table-xls-button hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
            table="table-to-xls"
            filename="tablexls"
            sheet="tablexls"
            buttonText="Download as XLS"
          />
        </div>
      </div>
    </>
  );
}
