import React from 'react'

const  Loader=()=> {
    return (
        <>
            <div>
                <div className="loader"> </div>
            </div>
        </>
    )
}

export default Loader