import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { GET } from "../../../axiosHelper/Helper";
import { failMsg } from "../../../utilities/Notifcation";
import Loader2 from "../../../utilities/Loader2";

export default function DepartmentTable() {
  //ADMIN DASHBOARD PAGE
  const [task, set_task] = useState([]);
  // const [ set_message] = useState("");
  // const [myAlert, setmyAlert] = useState(false);
  // const [ Auth,setAuth] = useState();
  // const [ setmyAlert_400] = useState(false);
  // const [delmsg, setDelmsg] = useState(true);
  // const [delId, setDelId] = useState();
  const [loader, setloader] = useState(false);
  const [AllTotals, setAllTotals] = useState({
    desk_all_total: "",
    completed_all_task: "",
    grand_all_total: "",
    total_all_task: "",
    from_date: "",
    to_date: "",
    y_desk_all_total: "",
    grand_cumulative_completed_total: "",
    grand_cumulative_grand_total: "",
    grand_cumulative_all_total: "",
    y_from_date: "",
    grand_total_lw: "",
  });




  // const [MergedData, setMergedData] = useState([]);

  // const [perweekTask, setPerweekTask] = useState([]);



  const onLoad = () => {
    setloader(true);
    GET('filter_task_by_department')
      .then((res) => {
        if (res.code === 500) {
          // setmyAlert(true);
        } else if (res.code === 200) {
          set_task(res.data);

          // setAuth(res.code);
          setAllTotals({
            desk_all_total: res.desk_all_total,
            completed_all_task: res.completed_all_task,
            grand_all_total: res.grand_all_total,
            total_all_task: res.total_all_task,
            from_date: res.from_date,
            to_date: res.to_date,
            y_desk_all_total: res.y_desk_all_total,
            grand_cumulative_completed_total: res.grand_cumulative_completed_total,
            grand_cumulative_grand_total: res.grand_cumulative_grand_total,
            grand_cumulative_all_total: res.grand_cumulative_all_total,
            grand_total_lw: res.grand_total_lw,
            grand_completed_lw: res.grand_completed_lw,
            y_from_date: res.y_from_date,
          });
          setloader(false);
        }
        if (res.code === 400) {
          console.log("res.message");
          failMsg(res.message);
          // setmyAlert_400(true);
          // set_message(res.message);
        } else {
        }
      }).catch(err => {
        failMsg(err);
        console.log(err)
      })
  }

  // const checkdeleteDesk = async (id) => {
  //   if (delmsg === true) {
  //     setDelmsg(false);
  //     setDelId(id);
  //   }
  // };

  // const delete_desk = async () => {
  //   let id = delId;

  //   await axios.delete(`${Api}task/${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   });
  //   onLoad();
  //   setDelmsg(true);
  // };

  useEffect(() => {
    onLoad();
    //eslint-disable-next-line
  }, []);

  const componentRef = useRef();


  return (
    <div>
      <div style={{ padding: "20px" }}>


        <div style={{ width: "100%" }} className="lg:overflow-hidden overflow-y-scroll">
          <table style={{ width: "100%" }} id="table-to-xls" ref={componentRef}>
            <thead>
              <th>
                <div className="w-full">
                  <p style={{ fontWeight: "bold", textAlign: "center", backgroundColor: "#8ea9db", padding: "20px", border: "1px solid black" }}>सह सचिव / उप सचिव निहाय प्रकरण निर्गतीचा साप्ताहिक अहवाल</p>
                </div>
              </th>
            </thead>
            <tbody>
              <tr style={{ width: "100%", display: "flex" }}>
                <table className="tg" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="tg-pfc6" rowSpan="2">
                        <span style={{ fontWeight: "bold" }}>अ.क्र.</span>
                      </td>
                      <td className="tg-pfc6" rowSpan="2">
                        <span style={{ fontWeight: "bold" }}>सह सचिव/ उप</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> सचिव यांचे नाव</span>
                      </td>
                      <td className="tg-pfc6" rowSpan="2">
                        <span style={{ fontWeight: "bold" }}>एकूण </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>कक्ष</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> संख्या</span>
                      </td>
                      <td className="tg-pfc6" rowSpan="2">
                        <span style={{ fontWeight: "bold" }}>एकूण संदर्भ </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>(मागील </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> शिल्लक +</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> आठवड्यात </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> प्राप्त)</span>
                      </td>
                      <td className="tg-pfc6" rowSpan="2">
                        <span style={{ fontWeight: "bold" }}>अहवाल</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> कालावधीत</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> निकाली काढलेले </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>संदर्भ</span>
                      </td>
                      <td className="tg-pfc6" colSpan="3">
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          दि. {AllTotals.from_date} ते दि. {AllTotals.to_date}
                        </span>
                      </td>
                      <td className="tg-jnmv" colSpan="3">
                        <span style={{ fontWeight: "bold" }}>दिनांक {AllTotals.y_from_date} पासून सादर केलेल्या प्रकरणांची संख्या</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="tg-jnmv">
                        <span style={{ fontWeight: "bold" }}>सह सचिव व उप </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>सचिव स्तरावर</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> सादर केलेल्या</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> प्रकरणाची एकूण </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>संख्या</span>
                        <br />
                      </td>
                      <td className="tg-jnmv">
                        <span style={{ fontWeight: "bold" }}>अ.मु.स./ इतर </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>विभाग/</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> शासनास सादर </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>केलेल्या </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>प्रकरणांची </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>संख्या</span>
                      </td>
                      <td className="tg-jnmv">
                        <span style={{ fontWeight: "bold" }}>सादर केलेल्या एकूण </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>प्रकरणांची संख्या</span>
                      </td>
                      <td className="tg-pfc6">
                        <span style={{ fontWeight: "bold" }}>सह सचिव उप </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>सचिव स्तरावरील</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}> प्रकरणे</span>
                        <br />
                      </td>
                      <td className="tg-pfc6">
                        <span style={{ fontWeight: "bold" }}>अ.मुस / इतर </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>विभाग/शासना</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>स सादर केलेली </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>प्रकरणे</span>
                        <br />
                      </td>
                      <td className="tg-pfc6">
                        <span style={{ fontWeight: "bold" }}>एकूण प्रकरणे</span>
                      </td>
                      <br />
                    </tr>
                    <tr>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>1</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>2</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>3</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>4</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>5</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>6 (अ)</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>6 (ब)</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>6(अ) +6 (ब) = 6क</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>7 (अ)</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>7 (ब)</span>
                      </td>
                      <td className="tg-ljj9">
                        <span style={{ fontWeight: "bold" }}>7(अ) +7(ब) = 7क</span>
                      </td>
                    </tr>
                    {loader ?<div className="absolute w-[96rem] py-4 flex justify-center"><Loader2 /></div>  : <>
                      {task.length === 0 ? (
                        <p className="ml-4 text-2xl font-bold w-full">No Data Available</p>
                      ) : (
                        <>

                          {task.map((task, index) => (
                            <tr className=" hover:text-white hover:bg-black transition-all">
                              <td className="tg-baqh">{index + 1}</td>
                              {task.department_name !== undefined ? <td className="tg-baqh"><Link to={`/dashboard/table/${task.dept_id}`}>{task.department_name}</Link> </td> : null}
                              {task.desk_count !== undefined ? <td className="tg-baqh">{task.desk_count}</td> : null}
                              {task.desk_count !== undefined ? <td className="tg-amwm">{task.l_total_task}</td> : null}
                              {task.desk_count !== undefined ? <td className="tg-amwm">{task.l_completed_task}</td> : null}
                              {task.total_task !== undefined ? <td className="tg-amwm">{task.total_task}</td> : null}
                              {task.total_task !== undefined ? <td className="tg-amwm">{task.completed_task}</td> : null}
                              {task.grand_total !== undefined ? <td className="tg-amwm">{task.grand_total}</td> : null}

                              {task.cumulative_sec_total !== undefined ? (
                                <td className="tg-sh4c">
                                  {" "}
                                  <span style={{ fontWeight: "bold" }}>{task.cumulative_sec_total}</span>
                                </td>
                              ) : null}
                              {task.cumulative_com_total !== undefined ? (
                                <td className="tg-sh4c">
                                  {" "}
                                  <span style={{ fontWeight: "bold" }}>{task.cumulative_com_total}</span>
                                </td>
                              ) : null}
                              {task.cumulative_grand_total !== undefined ? (
                                <td className="tg-sh4c">
                                  {" "}
                                  <span style={{ fontWeight: "bold" }}>{task.cumulative_grand_total}</span>
                                </td>
                              ) : null}
                            </tr>
                          ))}
                        </>
                      )}
                      <tr className="font-semibold">
                        <td className="tg-xrc8" colSpan="2">
                          <span >एकूण</span>
                        </td>
                        <td className="tg-l2ex">{AllTotals.desk_all_total}</td>
                        <td className="tg-l2ex">{AllTotals.grand_total_lw}</td>
                        <td className="tg-l2ex">{AllTotals.grand_completed_lw}</td>
                        <td className="tg-l2ex">{AllTotals.total_all_task}</td>
                        <td className="tg-l2ex">{AllTotals.completed_all_task}</td>
                        <td className="tg-l2ex">{AllTotals.grand_all_total}</td>
                        <td className="tg-l2ex">{AllTotals.grand_cumulative_all_total}</td>
                        <td className="tg-l2ex">{AllTotals.grand_cumulative_completed_total}</td>
                        <td className="tg-l2ex">{AllTotals.grand_cumulative_grand_total}</td>
                      </tr>
                    </>}



                  </tbody>
                </table>


              </tr>
            </tbody>
          </table>


        </div>
        <div className="flex justify-end my-4">
          <ReactToPrint
            trigger={() => (
              <button
                type="button"
                className="py-2 px-4 flex mr-2 justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                <img src="https://img.icons8.com/ios-glyphs/20/ffffff/pdf.png" className="mr-2" alt="" />
                PDF
              </button>
            )}
            content={() => componentRef.current}
          />
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button py-2 px-4  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
            table="table-to-xls"
            filename="tablexls"
            // filename={String('tablexls') + '.xlsx' } 

            sheet="tablexls"
            buttonText="Download as XLS new"
          />
        </div>
      </div>
    </div>
  );
}
