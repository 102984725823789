import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const [mobile_nav, setmobile_nav] = useState(false);
  const mobileMenu = () => {
    if (mobile_nav === false) {
      setmobile_nav(true);
    } else {
      setmobile_nav(false);
    }
  };
  const logOut = () => {
    sessionStorage.removeItem("token");
    sessionStorage.clear();
    // mobileMenu();
    navigate("/");
  };

  return (
    <>
      <div className="sticky top-0 z-10 Desk_Nav">
        <header className="z-10 shadow-xl nav_bar ">
          <div className="container-fluid mynav_p">
            <div className="main_nav ">
              <div className="nav_left">
                <div className="logo">
                  <NavLink to="/">
                    <img src={logo} alt="" />
                  </NavLink>
                </div>
                <div className="menu_items">
                  <nav className="navbar1">
                    <ul
                      className={`${
                        mobile_nav ? "nav-menu1 myactive" : "nav-menu1"
                      } text-blue-600 lg:text-base  `}
                    >
                      <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink
                          to="/dashboard"
                          className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                        >
                          Dashboard
                        </NavLink>
                      </li>

                      <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink
                          to="/department"
                          className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                        >
                          HOD (department)
                        </NavLink>
                      </li>

                      <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink
                          to="/desk"
                          className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                        >
                          DESK
                        </NavLink>
                      </li>

                      {sessionStorage.getItem("user_role") !== "4" ? (
                        <li className="nav-item1 " style={{ margin: "0px" }}>
                          <NavLink
                            to="/report"
                            className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                          >
                            REPORT
                          </NavLink>
                        </li>
                      ) : null}

                      {/* <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink to="/table1" className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white">
                          TABLE 1
                        </NavLink>
                      </li> */}

                      {/* <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink to="/table2" className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white">
                          TABLE 2
                        </NavLink>
                      </li> */}
                    </ul>
                    <div
                      className={`${
                        mobile_nav ? "hamburger myactive" : "hamburger"
                      }`}
                      onClick={mobileMenu}
                    >
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="nav_right">
                <div className="cta">
                  <span
                    className="px-3 py-2 mx-2 text-white transition-all duration-500 bg-blue-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                    onClick={logOut}
                  >
                    Logout
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      <div className="sticky top-0 z-10 Mobile_Nav">
        <header className="z-10 shadow-xl nav_bar ">
          <div className="container-fluid mynav_p">
            <div className="main_nav ">
              <div className="nav_left">
                <div className="logo">
                  <NavLink to="/">
                    <img src={logo} alt="" />
                  </NavLink>
                </div>
                <div className="menu_items">
                  <nav className="navbar1">
                    <ul
                      className={`${
                        mobile_nav ? "nav-menu1 myactive" : "nav-menu1"
                      } text-blue-600 lg:text-base 2xl:text-5xl `}
                    >
                      <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink
                          to="/dashboard"
                          className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                          onClick={mobileMenu}
                        >
                          Dashboard
                        </NavLink>
                      </li>
                      <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink
                          to="/department"
                          className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                        >
                          HOD (department)
                        </NavLink>
                      </li>

                      <li className="nav-item1 " style={{ margin: "0px" }}>
                        <NavLink
                          to="/desk"
                          className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                        >
                          DESK
                        </NavLink>
                      </li>
                      <li className="nav-item1">
                        <NavLink
                          to="#"
                          onClick={logOut}
                          className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                        >
                          logOut
                        </NavLink>
                      </li>
                    </ul>
                    <div
                      className={`${
                        mobile_nav ? "hamburger myactive" : "hamburger"
                      }`}
                      onClick={mobileMenu}
                    >
                      <span className="bar"></span>
                      <span className="bar"></span>
                      <span className="bar"></span>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="nav_right">
                <div className="cta">
                  <NavLink
                    to="#"
                    className="px-3 py-2 mx-2 transition-all duration-500 rounded-sm nav-link1 hover:bg-blue-600 hover:text-white"
                    onClick={logOut}
                  >
                    logOut
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Navbar;
