import React, { useState } from 'react'
import DepartmentTable from './admin_dashboard/DepartmentTable'
import HodTable from './HodTable'
import DeskTable from './Desk_dashboard/DeskTable'


const Dashboard = () => {
  const [userRole] = useState(sessionStorage.getItem('user_role'))
  return (
    <>
    {
      userRole === "2"?<DepartmentTable/>: userRole === "3" ? <HodTable/>: userRole === "4" ? <DeskTable/> : null
      
      
    }
    </>
  )
}

export default Dashboard