import React from 'react'
import Loader  from "./Loader";

const  Button=({ text, handleClick,loader })=> {
  return (
    <>
    <button
      type='submit'
        onClick={handleClick}
        className="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg flex justify-center"
      >
        {!loader?text:<Loader/>}
      </button>
    </>
  )
}

export default Button
