import React, { useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Loader2 from '../../utilities/Loader2'
import { GET } from "../../axiosHelper/Helper";
import { failMsg } from "../../utilities/Notifcation";


export default function HodTable() {
  // HOD DASHBOARD PAGE
  const [loading, setloading] = useState(false)
  const [desk, set_desk] = useState([]);
 
  const [AllTotals, setAllTotals] = useState({
    desk_all_total: "",
    completed_all_task: "",
    grand_all_total: "",
    total_all_task: "",
    from_date: "",
    to_date: "",
    y_desk_all_total: "",
    y_completed_all_task: "",
    y_grand_all_total: "",
    y_total_all_task: "",
    grand_l_completedtask : "",
    grand_cumulative_sec_total : "",
    grand_cumulative_com_total : "",
    grand_l_totaltask : "",
    grand_cumulative_grand_total : "",
    y_from_date: "",
  });
  const [user_name] = useState(sessionStorage.getItem("user_name"));

 
  const onLoad = () => {
    setloading(true);
    GET('filter_task_by_desk')
      .then((res) => {
        if (res.code === 500) {
          // setmyAlert(true);
        } else if (res.code === 200) {
          set_desk(res.data);

          // setAuth(res.code);
          setAllTotals({
            desk_all_total: res.desk_all_total,
            completed_all_task: res.completed_all_task,
            grand_all_total: res.grand_all_total,
            total_all_task: res.total_all_task,
            from_date: res.from_date,
            to_date: res.to_date,
            y_desk_all_total: res.y_desk_all_total,
            grand_cumulative_completed_total: res.grand_cumulative_completed_total,
            grand_cumulative_grand_total: res.grand_cumulative_grand_total,
            grand_cumulative_all_total: res.grand_cumulative_all_total,
            grand_total_lw: res.grand_total_lw,
            grand_completed_lw: res.grand_completed_lw,
            y_from_date: res.y_from_date,
          });
          setloading(false);
        }
        if (res.code === 400) {
          console.log(res.message);
          failMsg(res.message)
          // setmyAlert_400(true);
          // set_message(res.message);
        } else {
        }
      }).catch(err => {
       
        failMsg(err);
        console.log(err)
      })
  }
  // const checkdeleteDesk = async (id) => {
  //   if (delmsg == true) {
  //     setDelmsg(false);
  //     setDelId(id);
  //   }
  // };

  // const delete_desk = async () => {
  //   let id = delId;

  //   await axios.delete(`${Api}task/${id}`, {
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   });
  //   onLoad();
  //   setDelmsg(true);
  // };

  useEffect(() => {
    
    onLoad();
    
    
  }, []);

  const componentRef = useRef();

  return (
    <div className="p-5">
     

      <div style={{ width: "100%", display: "flex" }} className="lg:overflow-hidden overflow-y-scroll">
        <table style={{ width: "100%" }} id="table-to-xls"  ref={componentRef}>
          <thead>
            <th>
              <div className="w-full">
                <p style={{ fontWeight: "bold", textAlign: "center", backgroundColor: "#8ea9db", padding: "20px", border: "1px solid black" }}> सह सचिव / उप सचिव निहाय प्रकरण निर्गतीचा साप्ताहिक अहवाल {user_name}</p>
              </div>
            </th>
          </thead>
          <tbody>
            <tr style={{ width: "100%", display: "flex" }}>
              <table class="tg1" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th class="tg1-bcwe border border-black p-2" rowspan="2">
                      <span style={{ fontWeight: "bold" }}>अ.क्र.</span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2" rowspan="2">
                      <span style={{ fontWeight: "bold" }}>कार्यासन </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>क्र.</span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2" rowspan="2">
                      <span style={{ fontWeight: "bold" }}>एकूण संदर्भ </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>(मागील</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> शिल्लक + </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>आठवड्यात</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> प्राप्त)</span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2" rowspan="2">
                      <span style={{ fontWeight: "bold" }}>अहवाल कालावधीत</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> निकाली काढलेले</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> संदर्भ</span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2" colspan="3">
                      <span style={{ fontWeight: "bold" }}>
                        दि. {AllTotals.from_date} ते दि. {AllTotals.to_date}
                      </span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2" colspan="3">
                      <span style={{ fontWeight: "bold" }}>दिनांक {AllTotals.y_from_date} पासून सादर केलेल्या प्रकरणांची संख्या</span>
                    </th>
                  </tr>
                  <tr>
                    <th class="tg1-bcwe border border-black p-2">
                      <span style={{ fontWeight: "bold" }}>सह सचिव व उप</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> सचिव स्तरावर </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>सादर केलेल्या</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> प्रकरणाची एकूण</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> संख्या</span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2">
                      <span style={{ fontWeight: "bold" }}>अ.मु.स./ इतर</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> विभाग/ शासनास</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> सादर केलेल्या </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>प्रकरणांची संख्या</span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2">
                      <span style={{ fontWeight: "bold" }}>सादर केलेल्या एकूण</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> प्रकरणांची संख्या</span>
                    </th>
                    <th class="tg1-bcwe border border-black p-2">
                      <span style={{ fontWeight: "bold" }}>सह सचिव उप </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>सचिव स्तरावरील</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> प्रकरणे</span>
                      <br />
                      <br />
                      <br />
                    </th>
                    <th class="tg1-bcwe border border-black p-2">
                      <span style={{ fontWeight: "bold" }}>अ.मुस / इतर </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>विभाग/शासनास </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>सादर केलेली </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>प्रकरणे</span>
                      <br />
                      <br />
                    </th>
                    <th class="tg1-bcwe border border-black p-2">
                      <span style={{ fontWeight: "bold" }}>एकूण प्रकरणे</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="tg1-evkt border border-black p-2">1</td>
                    <td class="tg1-evkt border border-black p-2">2</td>
                    <td class="tg1-evkt border border-black p-2">3</td>
                    <td class="tg1-evkt border border-black p-2">4</td>
                    <td class="tg1-evkt border border-black p-2">5(अ)</td>
                    <td class="tg1-evkt border border-black p-2">5(ब)</td>
                    <td class="tg1-evkt border border-black p-2">5(अ) + 5(ब) = 5(क)</td>
                    <td class="tg1-evkt border border-black p-2">6 (अ)</td>
                    <td class="tg1-evkt border border-black p-2">6 (ब)</td>
                    <td class="tg1-evkt border border-black p-2">6(अ) +6 (ब) = 6क</td>
                  </tr>
                  {loading?<div className="p-20 flex justify-center items-center absolute w-full  "><Loader2/></div>:desk.length !== 0 ? (
                    <>
                      { desk.map((task, index) => (
                        <tr>
                          <>
                            <td class="tg1-c3ow border border-black p-2">{index + 1}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.desk_name}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.l_secretary_total}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.l_completed_task}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.total_task}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.completed_task}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.grand_total}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.cumulative_sec_total}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.cumulative_com_total}</td>
                            <td class="tg1-c3ow border border-black p-2">{task.cumulative_grand_total}</td>
                            
                          </>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p>no data</p>
                  )}
                  <tr>
                    <td class="tg1-7btt border border-black p-2" colspan="2">
                      एकूण
                    </td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.grand_l_totaltask}</td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.grand_l_completedtask}</td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.total_all_task}</td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.completed_all_task}</td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.grand_all_total}</td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.grand_cumulative_sec_total}</td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.grand_cumulative_com_total}</td>
                    <td class="tg1-7btt border border-black p-2">{AllTotals.grand_cumulative_grand_total}</td>
                  </tr>
                </tbody>
              </table>

              {/* <table className="tg1" style={{ width: "40%" , display : "none" }}>
                <thead>
                  <tr>
                    <th class="tg1-bcwe" colspan="3">
                      <span style={{ fontWeight: "bold" }}>दिनांक {AllTotals.y_from_date} पासून सादर केलेल्या प्रकरणांची संख्या</span>
                    </th>
                  </tr>
                  <tr>
                    <th class="tg1-bcwe">
                      <span style={{ fontWeight: "bold" }}>सह सचिव उप </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>सचिव स्तरावरील</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}> प्रकरणे</span>
                      <br />
                      <br />
                      <br />
                    </th>
                    <th class="tg1-bcwe">
                      <span style={{ fontWeight: "bold" }}>अ.मुस / इतर </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>विभाग/शासनास </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>सादर केलेली </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>प्रकरणे</span>
                      <br />
                      <br />
                    </th>
                    <th class="tg1-bcwe">
                      <span style={{ fontWeight: "bold" }}>एकूण प्रकरणे</span>
                    </th>
                  </tr>
                  <tr>
                    <td class="tg1-evkt">6 (अ)</td>
                    <td class="tg1-evkt">6 (ब)</td>
                    <td class="tg1-evkt">6(अ) +6 (ब) = 6क</td>
                  </tr>
                </thead>
                <tbody>
                  {perweekTask.length != 0 ? (
                    <>
                      {perweekTask.map((ytask, index) => (
                        <tr>
                          <>
                            <td class="tg1-c3ow">{ytask.total_task}</td>
                            <td class="tg1-c3ow">{ytask.completed_task}</td>
                            <td class="tg1-c3ow">{ytask.grand_total}</td>
                          </>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <p>no data</p>
                  )}
                  <tr>
                    <td class="tg1-7btt">{AllTotals.y_total_all_task}</td>
                    <td class="tg1-7btt">{AllTotals.y_completed_all_task}</td>
                    <td class="tg1-7btt">{AllTotals.y_grand_all_total}</td>
                  </tr>
                </tbody>
              </table> */}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-end my-4">
      <ReactToPrint
        trigger={() => (
            <button
              type="button"
              class="py-2 px-4 flex mr-2 justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              <img src="https://img.icons8.com/ios-glyphs/20/ffffff/pdf.png" className="mr-2" alt="" />
              PDF
            </button>
        )}
        content={() => componentRef.current}
        />
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button py-2 px-4  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg" table="table-to-xls" filename="tablexls" sheet="tablexls" buttonText="Download as XLS" />
        </div>
      {/* 
      <div className="mt-5">
        <div className="p-4">
          <div className="w-full text-center font-bold border-2 border-black p-2">
          साप्ताहिक प्रकरणांचा अहवाल दि. {AllTotals.from_date} ते दि. {AllTotals.to_date}
          </div>
          <div className="lg:overflow-x-auto flex overflow-x-scroll">
            <table className="tg1 ">
              <thead>
                <tr>
                  <th className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">अ.क्र.</span>
                  </th>
                  <th className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">कार्यासन क्र.</span>
                  </th>
                  <th className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">व उप</span>
                    <br />
                    <span className="font-bold">सचिव स्तरावरसादर केलेल्याप्रकरणाची एकूणसंख्या</span>
                  </th>
                  <th className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">अ.मु.स./ इतर</span>
                    <br />
                    <span className="font-bold">विभाग/ शासनाससादर केलेल्या प्रकरणांची संख्या</span>
                  </th>
                  <th className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">सादर केलेल्या</span>
                    <br />
                    <span className="font-bold">एकूण प्रकरणांची संख्या</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">1</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">2</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">3(अ)</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">4(ब)</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">5(क)</span>
                  </td>
                </tr>
               {
                 desk.length != 0 ?
                 (<>
                {desk.map((task, index) => (
                  <tr>
                    <>
                      <td className="tg1-c3ow border p-4 dark:border-dark-5">{index + 1}</td>
                      <td className="tg1-c3ow border p-4 dark:border-dark-5">{task.desk_name}</td>
                      <td className="tg1-c3ow border p-4 dark:border-dark-5">{task.total_task}</td>
                      <td className="tg1-c3ow border p-4 dark:border-dark-5">{task.completed_task}</td>
                      <td className="tg1-c3ow border p-4 dark:border-dark-5">{task.grand_total} </td>
                    </>
                  </tr>
                ))}
                </>):

                (
                  <p>no data</p>
                )
                }

                <tr>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5"></td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">एकूण</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">{AllTotals.total_all_task}</td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">{AllTotals.completed_all_task}</td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">{AllTotals.grand_all_total}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <thead>
                <tr>
                  <th className="tg1-c3ow border p-4 dark:border-dark-5" colspan="3">
                    <span className="font-bold">दिनांक {AllTotals.y_from_date} पासून सादर केलेल्या प्रकरणांची संख्या</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">सह सचिव उप सचिव स्तरावरील प्रकरणे 6 (अ)</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold"> अ.मुस / इतर विभाग/शासनास सादर केलेली</span>
                    <br />
                    <span className="font-bold">प्रकरणे 6 (ब)</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">एकूण प्रकरणे 6(अ) +6 (ब) = 6क</span>
                  </td>
                </tr>
                {perweekTask.length != 0 ? (
                  <>
                    {perweekTask.map((ytask, index) => (
                      <tr>
                        <>
                          <td className="tg1-c3ow border p-4 dark:border-dark-5">{ytask.total_task}</td>
                          <td className="tg1-c3ow border p-4 dark:border-dark-5">{ytask.completed_task}</td>
                          <td className="tg1-c3ow border p-4 dark:border-dark-5">{ytask.grand_total}</td>
                        </>
                      </tr>
                    ))}
                  </>
                ) : (
                  <p>no data</p>
                )}
                <tr>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">{AllTotals.y_total_all_task}</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold">{AllTotals.y_completed_all_task}</span>
                  </td>
                  <td className="tg1-c3ow border p-4 dark:border-dark-5">
                    <span className="font-bold ">{AllTotals.y_grand_all_total}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
}
