import { Route, Routes } from "react-router-dom";
import "./App.css";
import PageNotFound from "./Components/PageNotFound";
import { ToastContainer } from "react-toastify";
import Login from "./screens/login/Login";
import Layout from "./Components/Layout";
import Dashboard from "./screens/Dashboard/Dashboard";
import AdminHODDeskTable from "./screens/Dashboard/admin_dashboard/AdminHODDeskTable"
import AddTask from "./screens/Dashboard/Desk_dashboard/AddTask";
import EditTask from "./screens/Dashboard/Desk_dashboard/EditTask";
import HodCrudTable from "./screens/HodCrud/HodCrudTable";
import AddHodCrudTable from "./screens/HodCrud/AddHodCrudTable"
import EditHodCrudTable from "./screens/HodCrud/EditHodCrudTable"
import DeskCrudTable from "./screens/Desk/DeskCrudTable";
import AddDeskCrudTable from "./screens/Desk/AddDeskCrudTable"
import EditDeskCrudTablee from "./screens/Desk/EditDeskCrudTable"
import Report from "./screens/Report/Report";


function App() {
  return (
    <>
      <ToastContainer position={'top-center'}/>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route element={<Layout/>}>
          <Route path="/dashboard" element={<Dashboard/>}/>

          <Route path="/dashboard/table/:id" element={<AdminHODDeskTable/>}/> {/* get desk data of hod on admin dashboard*/}
          <Route path="/dashboard/task/add" element={<AddTask/>}/> {/* add task on dashboard of desk login*/}
          <Route path="/dashboard/task/edit/:id" element={<EditTask/>}/> {/* edit task on dashboard of desk login*/}

          <Route path="/department" element={<HodCrudTable/>}/>
          <Route path="/department/add" element={<AddHodCrudTable/>} />
          <Route path="/department/edit/:id" element={<EditHodCrudTable/>} />
          
          <Route path ="/desk" element={<DeskCrudTable/>}/>
          <Route path ="/desk/add" element={<AddDeskCrudTable/>}/>
          <Route path ="/desk/edit/:id" element={<EditDeskCrudTablee/>}/>

          <Route path="/report" element={<Report/>}/>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
