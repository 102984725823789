import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GET, PUT } from '../../axiosHelper/Helper'
import Button from '../../utilities/Button'
import Dropdown from '../../utilities/Dropdown'
import Inputfileds from '../../utilities/Inputfileds'
import { failMsg, successMsg } from '../../utilities/Notifcation'

const EditHodCrudTable = () => {
  const { id } = useParams();
  const navigate=useNavigate();
  const initialState = { full_name: "", email_id: "", password: "", confirmed: "", role: "", }
  const [formData, setformData] = useState(initialState)
  const [formError, setformError] = useState(initialState)
  const [isLoading, setisLoading] = useState(false)
  const [focus, setfocus] = useState(false)
  const handleChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value })
  }
  const formValidation = (object) => {
    const error = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (object.full_name.length === 0) {
      error.full_name = "Please fill the Full Name !"
    }
    if (object.email_id.length === 0) {
      error.email_id = "Please fill the Email Id!"
    } else if (!regex.test(object.email_id)) {
      error.email_id = "This is not a valid Email Format !";
    }
    if (object.password.length === 0) {
      error.password = "Please fill the Password !"
    } else if (object.password.length < 3) {
      error.password = "Password must be greater than 3 characters"
    }

    if (object.confirmed.length === 0) {
      error.confirmed = "Please fill the Confirm Password !"
    } else if (object.password !== object.confirmed) {
      error.confirmed = "Password and Confirm Password should same !"
    } else if (object.confirmed.length < 3) {
      error.confirmed = "Confirm Password must be greater than 3 characters"
    }
    if (object.role.length === 0) {
      error.role = "Please Select the Role !"
    }

    return error;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setfocus(true);
    setisLoading(true);
    // setformError(formValidation(formData))

    if (Object.keys(formValidation(formData)).length === 0) {
      updateDeparment();

    }
    else {

      setisLoading(false);
    }
  }
  //eslint-disable-next-line
  const updateDeparment = () => {
    PUT(`department/${id}`,formData)
      .then(res => {
        if(res.code === 200){
          successMsg(res.message)
          navigate('/department');
        }
        else{
          failMsg(JSON.stringify(res.message));
        }
        setisLoading(false);
      })
      .catch(err => {
        failMsg(err);
        setisLoading(false);
      })
  }

  useEffect(() => {
    if (focus) {
      setformError(formValidation(formData))
    }


  }, [formData, focus])


  const loadDepartment = () => {
    GET(`department/${id}`)
      .then(res => {
        setformData({ ...formData, ...res.data });
        setisLoading(false);
      })
      .catch(err => {
        failMsg(err);
        setisLoading(false);
      })
  }
  useEffect(() => {
    setisLoading(true)
    loadDepartment();
    //eslint-disable-next-line
  }, [])
  return (
    <>
      <div className='formContainer'>
        <div className='flex justify-between items-center'>
          <h1 className='heading'>Edit Department</h1>
          <Link className='backBtn' to={'/department'}>Back</Link>
        </div>
        <form className='filedsContainer' onSubmit={handleSubmit} >

          <Inputfileds label={'Full Name'} error={formError.full_name} type={'text'} placeHolder={'Full Name'} name={'full_name'} value={formData.full_name} handleChange={handleChange} />
          <Inputfileds label={'Email'} error={formError.email_id} type={'email'} placeHolder={'Email'} name={'email_id'} value={formData.email_id} handleChange={handleChange} />
          <Inputfileds label={'Password'} error={formError.password} type={'password'} placeHolder={'Password'} name={'password'} value={formData.password} handleChange={handleChange} />
          <Inputfileds label={'Confirm Password'} error={formError.confirmed} type={'password'} placeHolder={'Confirm Password'} name={'confirmed'} value={formData.confirmed} handleChange={handleChange} />
          <div className='lg:col-span-2'>
            <Dropdown label={'Role'} error={formError.role} name={'role'} value={formData.role} handleChange={handleChange}>
              <option value="2">Admin</option>
              <option value="3">HOD (department)</option>
              <option value="4">Desk</option>
            </Dropdown>
          </div>
          <div className='lg:col-span-2'>
            <Button text={'Edit Department'} loader={isLoading} />
          </div>

        </form>
      </div>
    </>
  )
}

export default EditHodCrudTable
