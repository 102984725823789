import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import '../index.css';
import Navbar from './Navbar';


const Layout = () => {
  const navigate=useNavigate();

  useEffect(() => {
    const token=sessionStorage.getItem('token');
    if(token===null || token=== undefined){
      navigate("/");
      
    }
  //eslint-disable-next-line
  }, [])
  return (
    <>
    <Navbar/>
    <Outlet/>
    </>
  )
}

export default Layout