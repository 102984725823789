import React, { useEffect, useState } from 'react'
import logo from '../../assets/logo.png'
import Inputfileds from '../../utilities/Inputfileds'
import Dropdown from '../../utilities/Dropdown'
import Button from '../../utilities/Button'
import { POST } from '../../axiosHelper/Helper'
import { failMsg, Notify } from '../../utilities/Notifcation'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'


const Login = () => {
    const navigate = useNavigate();
    const initial = {
        email_id: '',
        password: '',
        role: '',
    };
    const [formData, setformData] = useState(initial);
    const [load, setload] = useState(false);
    const [formError, setformError] = useState({})
    const [focus, setfocus] = useState(false)
    const handleChange = (e) => {
        setformData({ ...formData, [e.target.name]: e.target.value });


    }
    const formValidation = (object) => {
        const error = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (object.email_id.length === 0) {
            error.email_id = "Please fill the Email Id!"
        } else if (!regex.test(object.email_id)) {
            error.email_id = "This is not a valid Email Format !";
        }
        if (object.password.length === 0) {
            error.password = "Please fill the Password !"
        }
        if (object.role.length === 0) {
            error.role = "Please Select the Role !"
        }

        return error;
    }

    const handleClick = (e) => {
        e.preventDefault();
        setfocus(true)
        setload(true)
        setformError(formValidation(formData))

        if (Object.keys(formValidation(formData)).length === 0) {
            loginApi();
        }
        else {

            setload(false);
        }

    }

    const loginApi = () => {
        POST("login/admin", formData)
            .then((item) => {
                console.log('all', item);
                if (item.code === 200) {
                    console.log('role', typeof (item.user.role), item.user.role)
                    sessionStorage.setItem('user_role', item.user.role)
                    sessionStorage.setItem('user_name', item.user.full_name)
                    sessionStorage.setItem('userId', item.user.id)
                    sessionStorage.setItem("token", item.token);
                    navigate("/dashboard");
                    setformData(initial)
                } else if (item.code === 500) {
                    Notify(toast.error, item.message);
                    setload(false);
                }
            })
            .catch((err) => {
                failMsg(err);
                console.log(err);
                setload(false)
            });
    }
    useEffect(() => {
        if (sessionStorage.getItem('token') !== null) {
            navigate("/dashboard");
        }
        //eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (focus) {
            setformError(formValidation(formData))
        }


    }, [formData, focus])

    return (
        <>
            <div className='w-screen h-screen bg-[#F3F4F6]  flex justify-center items-center'>
                <div className='xl:w-3/12 w-full mx-3 bg-white p-7 flex flex-col gap-4 shadow-lg rounded-md'>
                    <div className='font-bold text-2xl flex justify-center flex-col items-center'>
                        <img src={logo} alt="" className="w-1/2" />
                        <p>Login to your account</p>
                    </div>
                    <form onSubmit={handleClick} >

                        <div>
                            <Inputfileds label={'Email'} error={formError.email_id} type={'email'} placeHolder={'Email'} name={'email_id'} value={formData.email_id} handleChange={handleChange} />
                        </div>
                        <div>

                            <Inputfileds label={'Password'} error={formError.password} type={'password'} placeHolder={'Password'} name={'password'} value={formData.password} handleChange={handleChange} />
                        </div>
                        <div>
                            <Dropdown label={'Role'} error={formError.role} name={'role'} value={formData.role} handleChange={handleChange}>
                                <option value="2">Admin</option>
                                <option value="3">HOD (department)</option>
                                <option value="4">Desk</option>
                            </Dropdown>
                        </div>
                        <div className='my-3'>
                            <Button text={'Login'} loader={load} />
                        </div>


                    </form>
                </div>
            </div>
        </>
    )
}

export default Login