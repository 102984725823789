import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DELETE, GET } from "../../../axiosHelper/Helper";
import { deleteMsg, failMsg } from "../../../utilities/Notifcation";

export default function DeskTable() {
  // DESK DASHBOARD PAGE
  const [task, set_task] = useState([]);

  // const [message, set_message] = useState("");
  const [myAlert] = useState();
  // const [myAlert_400, setmyAlert_400] = useState(false);
  const [delmsg, setDelmsg] = useState(true);
  const [delId, setDelId] = useState();
  const [user_name] = useState(sessionStorage.getItem('user_name'))


  const onLoad = () => {
    GET('task')
      .then((res) => {
        if (res.code === 500) {
        } else if (res.code === 200) {
          set_task(res.data);

        }
        if (res.code === 400) {
          console.log("res.message");
        } else {
        }
      }).catch(err => {
        failMsg(err);
        console.log(err)
      })
  }
  const checkdeleteDesk = async (id) => {
    if (delmsg === true) {
      setDelmsg(false);
      setDelId(id);
    }
  };

  const delete_desk = async () => {
    let id = delId;
    DELETE(`task/${id}`)
        .then(res=>{
          deleteMsg(res.message);
          onLoad();

        })
        .catch(err=>{
          failMsg(err);
        })
    
    setDelmsg(true);
  };

  useEffect(() => {
    onLoad();
   
  }, []);

  return (
    <div className="w-[98.5vw] overflow-auto">
      <div className="px-4 ">
        <div className="flex justify-between px-4 py-6 w-full m-auto ">
          <p className="text-3xl font-bold ">{user_name} Task List</p>

          <Link
            type="button"
            class="py-2 px-4 flex justify-center items-center  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            to="/dashboard/task/add"
          >
            <img className="mr-2" src="https://img.icons8.com/android/20/ffffff/plus.png" alt=""/>
            Add Tasks
          </Link>
        </div>
        <div className={delmsg ? "hidden" : "absolute top-0 left-0 grid place-items-center w-full h-full z-40 overflow-y-hidden"} style={{ background: "#0000006e" }}>
          <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
            <div class="w-full h-full text-center">
              <div class="flex h-full flex-col justify-between">
                <svg width="40" height="40" class="mt-4 w-12 h-12 m-auto text-red-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"></path>
                </svg>
                <p class="text-gray-800 dark:text-gray-200 text-xl font-bold mt-4">Remove row</p>
                <p class="text-gray-600 dark:text-gray-400 text-xs py-2 px-6">Are you sure you want to delete this row ?</p>
                <div class="flex items-center justify-between gap-4 w-full mt-8">
                  <button
                    type="button"
                    onClick={() => delete_desk()}
                    class="py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => setDelmsg(true)}
                    class="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-red-500 focus:ring-offset-red-200 text-red-500  w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table className="table mx-auto p-4 w-full bg-white shadow rounded-lg overflow-y-scroll" style={{maxWidth : '90rem'}}>
          <thead>
            <tr>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-normal text-gray-900">#</th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">From Date</th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">To Date</th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">एकूण संदर्भ <br /> (मागील शिल्लक + आठवड्यात प्राप्त)</th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">अहवाल कालावधीत <br/> निकाली काढलेले संदर्भ</th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold text-gray-900">सह सचिव उप <br /> सचिव स्तरावर सादर <br/> केलेल्या प्रकरणाची एकूण संख्या</th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold   text-gray-900">अ.मु.म/ इतर विभाग <br /> शासनास सादर केलेल्या प्रकरणांची संख्या</th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold   text-gray-900">TOTAL </th>
              <th className="border-b-2 p-4 dark:border-dark-5 whitespace-nowrap font-bold   text-gray-900">Actions </th>
            </tr>
          </thead>
          <tbody>
         {
           myAlert===500 ? 
           <p>No Data Available</p>
           :
           <>
            {task.map((task, index) => (
              <tr key={index} className="text-center">
                <th>{index + 1}</th>
                <td>{task.from_date}</td>
                <td>{task.to_date}</td>
                <td>{task.l_secretary_total}</td>
                <td>{task.l_completed_task}</td>
                <td>{task.secretary_total}</td>
                <td>{task.department_total}</td>
                <td>{task.total}</td>
                <td className="py-7">
                  <Link to={`/dashboard/task/edit/${task.id}`} className="px-3 py-1 mx-2 transition ease-in duration-200 uppercase rounded-full hover:bg-blue-800 hover:text-white border-2 border-blue-900 focus:outline-none">
                    EDIT
                  </Link>
                  <button  disabled={true} style={{cursor:'not-allowed'}} className="px-3 py-1  transition ease-in duration-200 uppercase rounded-full hover:bg-red-800 hover:text-white border-2 border-red-900 focus:outline-none" onClick={() => checkdeleteDesk(task.id)}>
                    DELETE
                  </button>
                </td>
              </tr>
            ))}
            </>
          }
          </tbody>
          
        </table>
      </div>
    </div>
  );
}
