import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

export const Notify = (type, message) => {
  type(message);
};


export const successMsg=(msg)=>{
  Swal.fire(
    `${msg}`,
    '',
    'success'
  )
}


export const failMsg=(msg)=>{
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: `${msg}`,

  })
}

export const deleteModal =(deletefunction)=>{
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deletefunction();
      
    }
  })
}
export const deleteMsg=(msg)=>{
  Swal.fire(
    'Deleted!', 
    `${msg}`,
    'success'
  )
}

export const getTodayDate=()=>{
  let d= new Date();
  let month=d.getMonth()<10?`0${d.getMonth()+1}`:d.getMonth()+1;
  let date=d.getDate()<10?`0${d.getDate()}`:d.getDate();
  let today= `${d.getFullYear()}-${month}-${date}`;
  return today;
}


export const reverseString=(str)=>{
  return str.split('-').reverse().join('-');
 
}