import React from 'react'

const Inputfileds=({label,type,placeHolder,name,value,handleChange,error,min,disabled})=> {
  
  return (
    <div className='relative mb-5'>
     <label className='font-semibold' htmlFor={name}>{label}</label>
    <input
        type={type}
        // required={true}
        disabled={disabled}
        autoComplete={'off'}
        id={name}
        className={`rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent`}
        placeholder={ placeHolder}
        name={name}
        value={value}
        onChange={(e) =>handleChange(e)}
        min={min}
      />
      <p className='absolute  text-red-500 font-semibold'>{error}</p>
    </div>
  )
}

export default Inputfileds
