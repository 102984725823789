import React from 'react'

const Loader2=()=> {
    return (
        <>
            <div>
                <div className="loader2"> </div>
            </div>
        </>
    )
}

export default Loader2